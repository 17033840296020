.monaco-editor .weld-tag-inline__model--active {
  @apply cursor-pointer;
}

.monaco-editor .weld-tag-inline {
  @apply border-b border-black;
}

.dark .monaco-editor .weld-tag-inline {
  @apply border-white/70;
}

.monaco-editor .weld-tag-inline__raw {
  @apply border-dashed;
}

.monaco-editor .weld-tag-inline__model {
  @apply border-solid;
}

.monaco-editor .weld-tag__raw--before-content {
  width: 14px;
  height: 14px;
  vertical-align: middle;
  margin-bottom: 1px;
  margin-right: 2px;
  display: inline-block;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 8px;
  border-radius: 0.25rem;
}
