.d2h-file-header,
.d2h-info,
.d2h-file-list-wrapper {
  @apply hidden;
}

.d2h-file-wrapper {
  @apply rounded-none border-0;
}

.d2h-code-side-linenumber {
  @apply border-0 bg-transparent dark:text-gray-700;
}

.d2h-del {
  @apply dark:bg-red-500;
}
.d2h-ins {
  @apply dark:bg-green-500;
}

.d2h-code-line-ctn {
  @apply text-xs text-gray-800 dark:text-gray-100;
}

.d2h-code-side-emptyplaceholder,
.d2h-emptyplaceholder {
  @apply dark:bg-gray-800;
}

.d2h-code-line del,
.d2h-code-side-line del {
  @apply dark:bg-red-700;
}

.d2h-code-line ins,
.d2h-code-side-line ins {
  @apply dark:bg-green-700;
}

.d2h-files-diff {
  display: flex !important;
}
