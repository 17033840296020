.monaco-diff-editor.side-by-side .editor.modified {
  box-shadow: unset !important;
  @apply border-l;
}

.editor.original {
  @apply bg-gray-50;
  @apply border-red-500;
}

.monaco-editor:not(.vs-dark) .margin {
  background-color: unset !important;
}

.monaco-editor:not(.vs-dark),
.monaco-editor-background,
.monaco-editor .inputarea.ime-input {
  background-color: unset !important;
}

.monaco-diff-editor.side-by-side .editor.modified {
  @apply dark:border-gray-700;
}
