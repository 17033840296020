/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-reflex/styles.css";

@layer base {
  :root {
    --primary: 212 100% 48%;
    --primary-foreground: 0 0% 100%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --background: 0 0% 100%;
    --foreground: 180 2% 8%;
    --muted: 210 8% 95%;
    --muted-foreground: 222 6% 45%;
    --border: 214.3 31.8% 91.4%;
  }

  .dark {
    --primary: 212 100% 48%;
    --primary-foreground: 0 0% 100%;
    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 0 0% 98%;
    --background: 240 1% 15%;
    --foreground: 0 0% 100%;
    --muted: 220 2% 25%;
    --muted-foreground: 220 7% 65%;
    --border: 217.2 32.6% 17.5%;
  }
}

@layer base {
  *,
  ::before,
  ::after {
    @apply dark:border-gray-700;
  }
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

.dark *::-webkit-scrollbar {
  background-color: #3e3f41;
}
.dark *::-webkit-scrollbar-thumb {
  background: #262627;
  border: 2px solid #3e3f41;
  border-radius: 20px;
}

.react-datepicker__tab-loop {
  @apply absolute;
}
.react-datepicker__header {
  @apply dark:bg-gray-800;
}

.react-datepicker__month-container {
  @apply flex flex-col;
}

.react-datepicker__time-list-item {
  @apply dark:border-gray-700 dark:bg-gray-800 dark:text-white dark:hover:bg-gray-700;
}

.react-datepicker__header {
  @apply dark:border-gray-700 dark:bg-gray-800 dark:text-white;
}

.react-datepicker-time__header {
  @apply dark:border-gray-700 dark:bg-gray-800 dark:text-white;
}

.react-datepicker__month {
  @apply flex flex-col;
}

.react-datepicker__week {
  @apply flex justify-around;
}

.react-datepicker__day-names {
  @apply flex justify-around text-center text-xs font-medium text-gray-800;
}

.react-datepicker__day-name {
  @apply flex h-8 w-8 items-center justify-center rounded-full py-1;
}

.react-datepicker__day {
  @apply mb-1 flex h-8 w-8 items-center justify-center rounded-full py-1 text-sm leading-loose text-gray-700 transition;
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed opacity-50 hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-200;
}

.react-datepicker__day--selecting-range-start {
  @apply border-2 border-blue-500 bg-white;
}

.react-datepicker__day--selecting-range-end {
  @apply border-2 border-blue-500 bg-white;
}

.react-datepicker__day--selected {
  @apply bg-blue-500 text-white;
}

.react-datepicker__day--range-start {
  @apply bg-blue-500 text-white hover:bg-white hover:text-gray-700;
}

.react-datepicker__day--range-end {
  @apply bg-blue-500 text-white hover:bg-white hover:text-gray-700;
}

@keyframes grading-loading-keyframes {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animation-gradient-loading {
  background-size: 400% 400% !important;
  animation: grading-loading-keyframes 2s ease infinite;
  background: linear-gradient(
    90deg,
    rgba(212, 214, 218, 1) 0%,
    rgba(241, 242, 243, 1) 50%,
    rgba(212, 214, 218, 1) 100%
  );
}

.dark .animation-gradient-loading {
  background: linear-gradient(
    90deg,
    rgba(62, 63, 65, 1) 0%,
    rgba(81, 88, 98, 1) 50%,
    rgba(62, 63, 65, 1) 100%
  );
}

.intercom-launcher {
  display: none !important;
}
.launcher-default-open-icon {
  display: none !important;
}
