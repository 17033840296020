.react-datepicker__triangle {
  @apply hidden;
}

.react-datepicker__day-name {
  @apply dark:text-white;
}
.react-datepicker__day {
  @apply dark:text-white;
  @apply hover:bg-primary;
  @apply hover:text-white;
  @apply rounded-sm;
  border-radius: 0.125rem;
}
.react-datepicker__day:hover {
  border-radius: 0.125rem;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--selected {
  @apply bg-primary;
}

.react-datepicker__month-container {
  @apply dark:bg-gray-800;
  @apply dark:text-white;
}

.react-datepicker {
  @apply dark:border-gray-700;
}

.react-datepicker__day {
  @apply dark:text-white;
}

.react-datepicker__header {
  @apply bg-gray-50;
}
