.react-datepicker .react-datepicker__month-wrapper {
  @apply flex;
}

.react-datepicker .react-datepicker__month-text:hover {
  @apply bg-gray-200;
}
.dark .react-datepicker .react-datepicker__month-text:hover {
  @apply bg-gray-700;
}

.react-datepicker .react-datepicker__month--selected {
  @apply bg-blue-500;
  @apply text-white;
}

.react-datepicker .react-datepicker__month--selected:hover {
  @apply bg-blue-500;
}

.react-datepicker .react-datepicker__month-text--keyboard-selected {
  @apply bg-blue-500;
  @apply text-white;
}

.react-datepicker .react-datepicker__navigation-icon {
  @apply static;
}
